import React, { useState, useEffect } from "react"
import CurrencyFormatter from "../bigcommerce/CurrencyFormatter"
import StepButton from "./StepButton"

export default function ShippingMethodStep({ value }) {
  /* eslint-disable */
  const { checkout } = value.state
  const [fields, setFields] = useState({ shipping_option_id: "" })

  useEffect(() => {
    if (checkout.consignments && checkout.consignments.length > 0) {
      const {
        selected_shipping_option,
        available_shipping_options,
      } = checkout.consignments[0]
      if (selected_shipping_option) {
        setFields(prev => ({
          ...prev,
          shipping_option_id: selected_shipping_option.id,
        }))
      } else {
        if (available_shipping_options.length > 0) {
          setFields(prev => ({
            ...prev,
            shipping_option_id: available_shipping_options[0].id,
          }))
        }
      }
    }
  }, [])

  const handleChange = event => {
    const { name, value } = event.target
    setFields(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (fields.shipping_option_id) {
      value.fetchConsignments(fields)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row mx-0 mt-3">
        {checkout.consignments[0].available_shipping_options &&
        checkout.consignments[0].available_shipping_options.length > 0 ? (
          checkout.consignments[0].available_shipping_options.map(option => (
            <div
              key={option.id}
              className={`col-12 col-md-6 bg-light mb-3 ${option.id ===
                fields.shipping_option_id && "border border-dark"}`}
            >
              <div className="form-check p-2">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id={option.id}
                  value={option.id}
                  onChange={handleChange}
                  checked={option.id === fields.shipping_option_id}
                  required
                  style={{ height: 20, width: 20 }}
                />
                <label className="form-check-label ml-2" htmlFor={option.id}>
                  {option.description}
                  {" - "}
                  <CurrencyFormatter
                    currency={checkout.cart.currency.code}
                    amount={option.cost}
                  />
                </label>
              </div>
            </div>
          ))
        ) : (
          <div className="card mb-3">
            <div className="card-body">
              <p className="card-text">
                Unfortunately one or more items in your cart can't be shipped to
                your location. Please choose a different delivery address.
              </p>
            </div>
          </div>
        )}
      </div>

      <StepButton disabled={Boolean(!fields.shipping_option_id)} />
    </form>
  )
}

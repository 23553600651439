import React, { useContext, useEffect } from "react"
import { Link } from "gatsby"
import { CartContext } from "../../context/CartContext"

export default function OrderConfirmation({ name, order_id }) {
  const value = useContext(CartContext)

  useEffect(() => {
    console.log("OrderConfirmation")
    value && value.fetchCart()
  }, [])

  return (
    <div>
      <h2 className="text-uppercase">{`THANK YOU ${name}!`}</h2>
      <p>{`Your order number is ${order_id}`}</p>
      <p className="mt-4">
        An email will be sent containing information about your purchase. If you
        have any questions about your purchase, email us at
        philippe.bodart@webriq.com.
      </p>
      <Link to="/shop" className="btn btn-dark mt-3">
        Continue Shopping
      </Link>
    </div>
  )
}

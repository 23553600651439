import React from "react"
import StepButton from "./StepButton"

export default function PaymentStep({ value }) {
  /* eslint-disable */
  const { payment_method } = value.state

  function showPaymentMethod(method) {
    switch (method.type) {
      case "card":
        return <CardComponent method={method} value={value} />
      default:
        return null
    }
  }

  return (
    <div>
      {payment_method && payment_method.length ? (
        payment_method.map(method => {
          const { accepted, id } = method
          if (accepted) {
            return <div key={id}>{showPaymentMethod(method)}</div>
          }
          return null
        })
      ) : (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
    </div>
  )
}

function CardComponent({ method, value }) {
  const { errors } = value.state
  const [fields, setFields] = React.useState({
    cardholder_name: "",
    number: "",
    expiry_month: "",
    expiry_year: "",
    verification_value: "",
  })

  const handleChange = event => {
    const { name, value } = event.target
    setFields(prev => ({ ...prev, [name]: value }))
  }

  function handleSubmit(e) {
    e.preventDefault()
    const data = {
      payment: {
        instrument: {
          type: method.type,
          ...fields,
          expiry_month: +fields.expiry_month,
          expiry_year: +fields.expiry_year,
        },
        payment_method_id: method.id,
      },
    }
    value.fetchProcessPayments(data)
  }

  const { supported_instruments } = method

  return (
    <div>
      {supported_instruments && (
        <div className="alert alert-info" role="alert">
          <small>Supported Instruments</small>
          <br />
          {supported_instruments.map(
            item =>
              item.verification_value_required && (
                <span className="ml-1" key={item.instrument_type}>
                  {item.instrument_type},
                </span>
              )
          )}
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="cardholder_name" className="mb-0">
            Card Name
          </label>
          <input
            name="cardholder_name"
            value={fields.cardholder_name}
            onChange={handleChange}
            type="text"
            className={`form-control ${
              errors && errors.invalid_cardholder_name ? "is-invalid" : ""
              }`}
            id="cardholder_name"
          />
          {errors && errors.invalid_cardholder_name && (
            <div className="invalid-feedback">
              {errors.invalid_cardholder_name}
            </div>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="number" className="mb-0">
            Card Number <small className="text-muted">(4242424242424242)</small>
          </label>
          <input
            name="number"
            value={fields.number}
            onChange={handleChange}
            type="number"
            className={`form-control ${
              errors && errors.invalid_number ? "is-invalid" : ""
              }`}
            id="number"
          />
          {errors && errors.invalid_number && (
            <div className="invalid-feedback">{errors.invalid_number}</div>
          )}
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="mb-3">
              <label htmlFor="expiry_month" className="mb-0">
                Expiry Month <small className="text-muted">(01)</small>
              </label>
              <input
                name="expiry_month"
                value={fields.expiry_month}
                onChange={handleChange}
                type="number"
                className={`form-control ${
                  errors && errors["payment.instrument.expiry_month"]
                    ? "is-invalid"
                    : ""
                  }`}
                id="expiry_month"
              />
              {errors && errors["payment.instrument.expiry_month"] && (
                <div className="invalid-feedback">
                  {errors["payment.instrument.expiry_month"]}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3">
              <label htmlFor="expiry_year" className="mb-0">
                Expiry Year <small className="text-muted">(2020)</small>
              </label>
              <input
                name="expiry_year"
                value={fields.expiry_year}
                onChange={handleChange}
                type="number"
                className={`form-control ${
                  errors && errors.expired_card ? "is-invalid" : ""
                  }`}
                id="expiry_year"
              />
              {errors && errors.expired_card && (
                <div className="invalid-feedback">{errors.expired_card}</div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3">
              <label htmlFor="verification_value" className="mb-0">
                Verification Value <small className="text-muted">(1234)</small>
              </label>
              <input
                name="verification_value"
                value={fields.verification_value}
                onChange={handleChange}
                type="text"
                className={`form-control ${
                  errors && errors.invalid_cvc ? "is-invalid" : ""
                  }`}
                id="verification_value"
              />
              {errors && errors.invalid_cvc && (
                <div className="invalid-feedback">{errors.invalid_cvc}</div>
              )}
            </div>
          </div>
        </div>

        <StepButton />
      </form>
    </div>
  )
}

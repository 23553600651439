import React from "react"

export default function Address({
  fields,
  handleFieldChange,
  shipping_zone,
  states,
}) {
  /* eslint-disable */
  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="mb-3">
            <label htmlFor="first_name" className="mb-0">
              First Name
            </label>
            <input
              className="form-control"
              type="text"
              id="first_name"
              name="first_name"
              required
              value={fields.first_name || ""}
              onChange={handleFieldChange}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="mb-3">
            <label htmlFor="last_name" className="mb-0">
              Last Name
            </label>
            <input
              className="form-control"
              type="text"
              id="last_name"
              name="last_name"
              required
              value={fields.last_name || ""}
              onChange={handleFieldChange}
            />
          </div>
        </div>
      </div>

      <div className="mb-3">
        <label htmlFor="company" className="mb-0">
          Company <small className="text-muted">(Optional)</small>
        </label>
        <input
          className="form-control"
          type="text"
          id="company"
          name="company"
          value={fields.company || ""}
          onChange={handleFieldChange}
        />
      </div>

      <div className="mb-3">
        <label htmlFor="phone" className="mb-0">
          Phone Number <small className="text-muted">(Optional)</small>
        </label>
        <input
          className="form-control"
          type="text"
          id="phone"
          name="phone"
          value={fields.phone || ""}
          onChange={handleFieldChange}
        />
      </div>

      <div className="mb-3">
        <label htmlFor="address1" className="mb-0">
          Address
        </label>
        <input
          className="form-control"
          type="text"
          id="address1"
          name="address1"
          required
          value={fields.address1 || ""}
          onChange={handleFieldChange}
        />
      </div>

      <div className="mb-3">
        <label htmlFor="address2" className="mb-0">
          Apartment/Suite/Building{" "}
          <small className="text-muted">(Optional)</small>
        </label>
        <input
          className="form-control"
          type="text"
          id="address2"
          name="address2"
          value={fields.address2 || ""}
          onChange={handleFieldChange}
        />
      </div>

      <div className="mb-3">
        <label htmlFor="city" className="mb-0">
          City
        </label>
        <input
          className="form-control"
          type="text"
          id="city"
          name="city"
          required
          value={fields.city || ""}
          onChange={handleFieldChange}
        />
      </div>

      <div className="mb-3">
        <label htmlFor="country_code" className="mb-0">
          Country
        </label>
        <select
          name="country_code"
          id="country_code"
          className="form-control"
          required
          value={fields.country_code || ""}
          onChange={handleFieldChange}
        >
          <option value="">Select country</option>
          {shipping_zone.length > 0 &&
            shipping_zone.map((zone, index) => (
              <option value={zone.country_iso2} key={index}>
                {zone.country}
              </option>
            ))}
        </select>
      </div>

      <div className="row">
        <div className="col-12 col-md-8">
          <div className="mb-3">
            <label htmlFor="state_or_province_code" className="mb-0">
              State/Province
            </label>
            <select
              name="state_or_province_code"
              id="state_or_province_code"
              className="form-control"
              required
              value={fields.state_or_province_code}
              onChange={handleFieldChange}
            >
              <option value="">Select state</option>
              {states.map((item, index) => (
                <option value={item.state_abbreviation} key={index}>
                  {item.state}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="mb-3">
            <label htmlFor="postal_code" className="mb-0">
              Postal Code
            </label>
            <input
              className="form-control"
              type="text"
              id="postal_code"
              name="postal_code"
              required
              value={fields.postal_code || ""}
              onChange={handleFieldChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import { CheckoutContext } from "../../context/CheckoutContext"

export default function StepButton({ disabled }) {
  const value = React.useContext(CheckoutContext)
  if (!value) return null

  const { isSubmitting } = value.state
  if (value.activeStep === 1) {
    return (
      <button type="submit" className="btn btn-dark" disabled={isSubmitting}>
        {isSubmitting ? "Loading..." : "continue as guest"}
      </button>
    )
  }

  if (value.activeStep === 5) {
    return (
      <div>
        {!isSubmitting ? (
          <button
            onClick={() => value.setActiveStep(value.activeStep - 1)}
            className="btn btn-outline-dark mr-3"
          >
            back
          </button>
        ) : null}
        <button type="submit" className="btn btn-dark" disabled={isSubmitting}>
          {isSubmitting ? "Loading..." : "place order"}
        </button>
      </div>
    )
  }

  return (
    <div>
      {!isSubmitting ? (
        <button
          onClick={() => value.setActiveStep(value.activeStep - 1)}
          className="btn btn-outline-dark mr-3"
        >
          back
        </button>
      ) : null}
      <button
        type="submit"
        className="btn btn-dark"
        disabled={isSubmitting || disabled}
      >
        {isSubmitting ? "Loading..." : "continue"}
      </button>
    </div>
  )
}

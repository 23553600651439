import React from "react"
import Address from "./Address"
import StepButton from "./StepButton"

export default function BillingStep({ value, acct_value }) {
  /* eslint-disable */
  const { customer, addresses } = acct_value.state
  const { checkout, countries, states } = value.state
  const [fields, setFields] = React.useState(checkout.billing_address)
  const [sameAddress, setSameAddress] = React.useState(true)

  if (states && states.length) {
    if (fields.state_or_province && !fields.state_or_province_code) {
      const selected = states.find(item => item.state === fields.state_or_province)
      setFields(prev => ({ ...prev, state_or_province_code: selected.state_abbreviation }))
    }
  }

  React.useEffect(() => {
    if (checkout) {
      if (checkout.billing_address && checkout.consignments[0]) {
        const x =
          checkout.billing_address.address1 ===
          checkout.consignments[0].shipping_address.address1
        setSameAddress(x)
      }
    }
  }, [])
  React.useEffect(() => {
    let id = ""
    if (fields.country_code) {
      const selected = countries.find(
        item => item.country_iso2 === fields.country_code
      )
      id = selected && selected.id
    }
    value.getAllCountryStates(id)
  }, [fields.country_code])

  const handleChange = event => {
    const { name, value } = event.target
    setFields(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (sameAddress) {
      if (checkout.consignments && checkout.consignments.length > 0) {
        const { shipping_address } = checkout.consignments[0]
        if (shipping_address) value.fetchBillingAddress(shipping_address)
      }
    } else {
      value.fetchBillingAddress(fields)
    }
  }

  return (
    <div>
      <div className="form-check mt-3 mb-3">
        <input
          className="form-check-input"
          type="checkbox"
          name="examplecheckbox"
          id={"sameAddress"}
          style={{ height: 20, width: 20 }}
          checked={sameAddress}
          onChange={() => setSameAddress(!sameAddress)}
        />
        <label className="form-check-label ml-2" htmlFor={"sameAddress"}>
          Same as shipping address
        </label>
      </div>

      <form onSubmit={handleSubmit}>
        {sameAddress ? null : (
          <div>
            {customer && customer.length ? (
              <div
                className="mb-3"
                style={{
                  display: "grid",
                  gridGap: "1rem",
                  gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr)",
                }}
              >
                {addresses.map(item => (
                  <button
                    type="button"
                    className="btn btn-light text-left"
                    key={item.id}
                    onClick={() => setFields(item)}
                  >
                    <small className="font-weight-bold">{`${item.first_name} ${item.last_name}`}</small>
                    <br />
                    <small>
                      {item.address2
                        ? `${item.address1} / ${item.address2}`
                        : item.address1}
                    </small>
                    <br />
                    <small>{`${item.city}, ${item.state_or_province}`}</small>
                    <br />
                    <small>{`${item.postal_code} / ${item.country}`}</small>
                  </button>
                ))}
              </div>
            ) : null}

            <Address
              fields={fields}
              handleFieldChange={handleChange}
              shipping_zone={countries}
              states={states}
            />
          </div>
        )}
        <StepButton />
      </form>
    </div>
  )
}

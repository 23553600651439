import React, { useState, useEffect } from "react"
import Address from "./Address"
import StepButton from "./StepButton"

export default function ShippingStep({ value, acct_value }) {
  /* eslint-disable */
  const { customer, addresses } = acct_value.state
  const { checkout, countries, states, isSubmitting } = value.state

  const initState = {
    email: checkout.cart.email || "",
    first_name: "",
    last_name: "",
    address1: "",
    address2: "",
    city: "",
    state_or_province_code: "",
    country_code: (countries.length && countries[0].country_iso2) || "US",
    postal_code: "",
    phone: "",
    company: "",
  }

  const [fields, setFields] = useState(initState)
  const [newAdd, setNewAdd] = useState(false)
  const [selectedAdd, setSelectedAdd] = useState(null)

  useEffect(() => {
    if (newAdd) {
      setFields(initState)
      setSelectedAdd(null)
    } else {
      if (checkout.consignments && checkout.consignments.length > 0) {
        const { shipping_address } = checkout.consignments[0]
        setFields(shipping_address)
        if (addresses.length) {
          const hasAdd = addresses.find(
            add => add.address1 === shipping_address.address1
          )
          setSelectedAdd(hasAdd ? hasAdd.id : null)
        }
      }
    }
  }, [newAdd])
  useEffect(() => {
    let id = ""
    if (fields.country_code) {
      const selected = value.state.countries.find(
        item => item.country_iso2 === fields.country_code
      )
      id = selected && selected.id
    }
    value.getAllCountryStates(id)
  }, [fields.country_code])
  useEffect(() => {
    if (fields.state_or_province_code) {
      const selected = states.find(
        item => item.state_abbreviation === fields.state_or_province_code
      )
      if (selected) {
        setFields(prev => ({ ...prev, state_or_province: selected.state }))
      }
    }
  }, [fields.state_or_province_code])

  const handleChange = event => {
    const { name, value } = event.target
    setFields(prev => ({ ...prev, [name]: value }))
  }
  const handleSubmit = e => {
    e.preventDefault()
    if (customer && customer.length) {
      acct_value.addCustomerAddress({ ...fields, customer_id: customer[0].id })
      value.fetchConsignments({ shipping_address: fields })
    } else {
      value.fetchConsignments({ shipping_address: fields })
    }
  }
  const handleContinue2 = () => {
    const selected = addresses.find(add => add.id === selectedAdd)
    if (selected) {
      value.fetchConsignments({ shipping_address: selected })
    }
  }

  if (customer && customer.length) {
    return (
      <div>
        <div
          className="mb-3"
          style={{
            display: "grid",
            gridGap: "1rem",
            gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr)",
          }}
        >
          {addresses.map(item => (
            <button
              className={`btn btn-light text-left ${
                selectedAdd === item.id ? "border-dark" : ""
              }`}
              key={item.id}
              onClick={() => {
                setSelectedAdd(item.id)
                setNewAdd(false)
              }}
            >
              <small className="font-weight-bold">{`${item.first_name} ${item.last_name}`}</small>
              <br />
              <small>
                {item.address2
                  ? `${item.address1} / ${item.address2}`
                  : item.address1}
              </small>
              <br />
              <small>{`${item.city}, ${item.state_or_province}`}</small>
              <br />
              <small>{`${item.postal_code} / ${item.country}`}</small>
            </button>
          ))}
        </div>

        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="newAdd"
            value={newAdd}
            checked={newAdd}
            onChange={() => setNewAdd(!newAdd)}
            style={{ height: 20, width: 20 }}
          />
          <label className="form-check-label ml-2" htmlFor="newAdd">
            Create new address
          </label>
        </div>

        {newAdd && (
          <form onSubmit={handleSubmit}>
            <Address
              fields={fields}
              handleFieldChange={handleChange}
              shipping_zone={countries}
              states={states}
            />

            <StepButton />
          </form>
        )}

        {!newAdd && (
          <div className="mt-3">
            <button
              className="btn btn-outline-dark"
              onClick={() => value.setActiveStep(1)}
            >
              cancel
            </button>

            {selectedAdd && (
              <button
                className="btn btn-dark ml-3"
                onClick={handleContinue2}
                disabled={isSubmitting}
              >
                {isSubmitting ? "loading" : "continue"}
              </button>
            )}
          </div>
        )}
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <Address
        fields={fields}
        handleFieldChange={handleChange}
        shipping_zone={countries}
        states={states}
      />

      <StepButton />
    </form>
  )
}

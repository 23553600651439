import React from "react"
import { Link } from "gatsby"
import CurrencyFormatter from "../bigcommerce/CurrencyFormatter"

export default function Cart({ value }) {
  const checkout = value.state.checkout
  const code = value.state.checkout.cart.currency.code
  const physical_items = value.state.checkout.cart.line_items.physical_items
  let physical_items_total_items = 0
  physical_items &&
    physical_items.forEach(item => {
      physical_items_total_items += item.quantity
    })

  const Item = ({ data, code }) => {
    const { id, image_url, quantity, name, options, sale_price } = data

    return (
      <div key={id} className="row m-0">
        <div className="col-md-3 text-center">
          <img src={image_url} alt={image_url} className="img-fluid mb-0" />
        </div>
        <div className="col-md-6">
          <p className="mb-0" style={{ fontSize: 14 }}>
            <small className="d-md-none text-muted mr-2">ITEM:</small>
            {`${quantity} x ${name}`}
          </p>
          {options.map(option => (
            <p
              className="text-muted mb-0"
              style={{ fontSize: 12 }}
              key={option.name_id}
            >{`${option.name} - ${option.value}`}</p>
          ))}
        </div>
        <div className="col-md-3">
          <p className="mb-0" style={{ fontSize: 14 }}>
            <small className="d-md-none text-muted mr-2">PRICE:</small>
            <CurrencyFormatter
              currency={code}
              amount={sale_price}
            />
          </p>
        </div>
      </div>
    )
  }

  const FooterLabel = ({ label, currency, amount }) => {
    return (
      <div className="d-flex">
        <p className="mb-0 mr-auto">{label}</p>
        <p className="mb-0">
          {amount === 0 ? "--" : <CurrencyFormatter currency={currency} amount={amount} />}
        </p>
      </div>
    )
  }

  return (
    <div className="card">
      <div className="card-header d-flex align-items-center">
        <p className="mb-0">Order Summary</p>
        <small className="mr-auto ml-2">{`(${physical_items_total_items} ${physical_items_total_items > 1 ? 'items' : 'item'})`}</small>
        <Link to="/cart" className="text-primary">Edit cart</Link>
      </div>

      <div className="py-2">
        {physical_items.map(item => <Item data={item} code={code} />)}
      </div>

      <div class="card-footer">
        <FooterLabel label="Subtotal" currency={code} amount={checkout.subtotal_ex_tax} />
        <FooterLabel label="Shipping" currency={code} amount={checkout.shipping_cost_total_ex_tax} />
        <FooterLabel label="Tax" currency={code} amount={checkout.tax_total} />

        <hr />
        <div className="d-flex">
          <h4 className="mb-0 mr-auto">{`TOTAL (${code})`}</h4>
          <h4 className="mb-0">
            <CurrencyFormatter currency={code} amount={checkout.grand_total} />
          </h4>
        </div>
      </div>
    </div>
  )
}

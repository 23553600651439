import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { CheckoutContext } from "../context/CheckoutContext"
import { AccountContext } from "../context/AccountContext"

import Cart from "../components/checkout/Cart"
import CustomerStep from "../components/checkout/CustomerStep"
import ShippingStep from "../components/checkout/ShippingStep"
import ShippingMethodStep from "../components/checkout/ShippingMethodStep"
import BillingStep from "../components/checkout/BillingStep"
import PaymentStep from "../components/checkout/PaymentStep"
import OrderConfirmation from "../components/checkout/OrderConfirmation"

export default function Checkout() {
  /* eslint-disable */
  const value = React.useContext(CheckoutContext)
  const acct_value = React.useContext(AccountContext)

  useEffect(() => {
    if (acct_value.state.customer) {
      const customer = acct_value.state.customer
      if (customer.length) {
        value.updateCartByCustomerId(customer[0].id)
      } else value.fetchCheckout()
    }
  }, [acct_value && acct_value.state && acct_value.state.customer])

  if (!value && !acct_value) return null

  function getSteps() {
    return ["Customer", "Shipping", "Shipping Method", "Billing", "Payment"]
  }
  const steps = getSteps()
  const { isLoading, checkout, order_id, hasError, errors } = value.state

  return (
    <Layout>
      <SEO title="Checkout" description="" />
      <div style={{ paddingTop: 200, paddingBottom: 50 }}>
        <div className="container">
          {isLoading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : checkout && checkout.cart ? (
            <div className="row">
              <div className="col-12 col-md-7">
                {hasError && (
                  <div className="alert alert-danger" role="alert">
                    <span>{hasError}</span>
                    <ul>
                      {errors.map((err, index) => (
                        <li key={index}>{err}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {steps.map((label, index) => (
                  <Step
                    key={index}
                    label={label}
                    step={index + 1}
                    activeStep={value.activeStep}
                    value={value}
                    acct_value={acct_value}
                  />
                ))}
                {value.activeStep > steps.length && (
                  <OrderConfirmation
                    name={checkout.billing_address.first_name}
                    order_id={order_id}
                  />
                )}
              </div>
              <div className="col-12 col-md-5">
                <Cart value={value} />
              </div>
            </div>
          ) : (
            <h1 className="text-center">No items in a cart</h1>
          )}
        </div>
      </div>
    </Layout>
  )
}

const Step = ({ step, label, activeStep, value, acct_value }) => {
  function getStepContent(step, value) {
    switch (step) {
      case 1:
        return <CustomerStep value={value} acct_value={acct_value} />
      case 2:
        return <ShippingStep value={value} acct_value={acct_value} />
      case 3:
        return <ShippingMethodStep value={value} />
      case 4:
        return <BillingStep value={value} acct_value={acct_value} />
      case 5:
        return <PaymentStep value={value} />
      default:
        return "Unknown step"
    }
  }

  return (
    <div>
      <h5>
        {activeStep >= step ? (
          <span className="badge badge-dark rounded-circle">&#10003;</span>
        ) : (
          <span className="badge badge-secondary rounded-circle">{step}</span>
        )}
        <span className="ml-3">{label}</span>
      </h5>

      {activeStep === step && (
        <div style={{ marginLeft: 40 }}>{getStepContent(step, value)}</div>
      )}
      <hr />
    </div>
  )
}

import React, { useState } from "react"
import { Link } from "gatsby"

export default function CustomerStep({ value, acct_value }) {
  /* eslint-disable */
  const { customer, isError, isSubmitting: cx_isSubmitting } = acct_value.state
  const { checkout, countries, isSubmitting } = value.state
  const { billing_address } = checkout

  const country_iso2 = countries.length && countries[0].country_iso2
  const initState = { email: "", country_code: country_iso2 || "US" }

  const [fields, setFields] = useState(billing_address || initState)
  const [signin, setSignin] = useState(false)

  const handleSignIn = () => {
    setSignin(!signin)
  }
  const handleChange = event => {
    const { name, value } = event.target
    setFields(prev => ({ ...prev, [name]: value }))
  }
  const handleSubmit = e => {
    e.preventDefault()
    if (signin) {
      acct_value.login({ email: fields.email, password: fields.password })
    } else {
      value.fetchBillingAddress(fields)
    }
  }

  if (customer.length > 0) {
    return (
      <div>
        <h5>{customer[0].email}</h5>
        <button
          className="btn btn-outline-dark mr-3"
          onClick={() => {
            acct_value.logout()
            value.updateCartByCustomerId(0)
          }}
          disabled={cx_isSubmitting}
        >
          {cx_isSubmitting ? "loading.." : "sign out"}
        </button>
        <button className="btn btn-dark" onClick={() => value.setActiveStep(2)}>
          continue
        </button>
      </div>
    )
  }

  return (
    <div>
      {signin && isError && (
        <div className="alert alert-danger" role="alert">
          {isError}
        </div>
      )}

      {signin && (
        <small className="m-0">
          Don't have an account?{" "}
          <Link to="/register" className="text-primary">
            Create an account
          </Link>{" "}
          to continue
        </small>
      )}

      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="email" className="mb-0">
            Email Address
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={fields.email}
            onChange={handleChange}
            required
          />
        </div>
        {signin && (
          <>
            <div className="mb-3">
              <label htmlFor="password" className="mb-0">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                value={fields.password || ""}
                onChange={handleChange}
                required
              />
              <a
                href="https://shop.swampfoxoptics.com/login.php?action=reset_password"
                target="_blank"
                className="text-primary"
              >
                <small>Forgot password?</small>
              </a>
            </div>
          </>
        )}

        {signin ? (
          <SignInActionButton
            handleSignIn={handleSignIn}
            cx_isSubmitting={cx_isSubmitting}
          />
        ) : (
          <div>
            <button
              type="submit"
              className="btn btn-dark"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Loading..." : "continue as guest"}
            </button>
            {/* <button
              type="button"
              className="btn btn-outline-dark ml-3"
              onClick={handleSignIn}
            >
              sign in
            </button> */}
          </div>
        )}
      </form>
    </div>
  )
}

const SignInActionButton = ({ handleSignIn, cx_isSubmitting }) => {
  return (
    <div>
      <button type="submit" className="btn btn-dark" disabled={cx_isSubmitting}>
        {cx_isSubmitting ? "loading.." : "sign in"}
      </button>
      <button
        type="button"
        className="btn btn-outline-dark ml-3"
        onClick={handleSignIn}
      >
        cancel
      </button>
    </div>
  )
}
